body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import "react-datepicker/dist/react-datepicker.css";

.date {
  height: 32px;
}

.react-datepicker__input-container input {
  height: 52px;
  font-size: 1rem;
  padding-left: 8px;
  border-radius: 5px;
  cursor: pointer;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  width: 120px;
}

.select-date-picker .react-datepicker__input-container input {
  height: 30px;
  width: 130px;
}
.repost-order-date-picker .react-datepicker__input-container input {
  height: 52px;
  width: 332px;
  padding-left: 16px;
}
